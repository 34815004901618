import React, { useEffect } from 'react'
import './offers.css'
import { IoIosBed } from "react-icons/io";
import { MdBathtub } from "react-icons/md";
import { FaWifi } from "react-icons/fa";
import { MdAirportShuttle } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";

import img from '../../assets/offer1.jpg'
import img2 from '../../assets/offer2.jpg'
import img3 from '../../assets/offer3.jpg'
import Aos from 'aos';
import 'aos/dist/aos.css';

const data = [
  {
    id:1,
    img:img,
    title:'Luxury House',
    location:'Rwanda-Kigali',
    price:'$ 1200',
    type:'Radisson'

  },
  {
    id:2,
    img:img2,
    title:'Double Room',
    location:'Rwanda-Nyungwe',
    price:'$ 2200',
    type:'One&Only Hotel'
  },
  {
    id:3,
    img:img3,
    title:'Presidential Room',
    location:'Rwanda-Rubavu',
    price:'$ 7200',
    type:'Serena Hotel'
  },
]
const Offers = () => {

  
  useEffect(()=>{
    Aos.init({duration: 2000})
  },[])
  return (
    <section className='section offer container'>
      <div className="secContainer">

        <div data-aos = 'fade-right' data-aos-duration = '2500' className="secIntro">
          <h2 className="secTitle">
            Special Offers
          </h2>
          <p>
          From historical cities to natural specteculars, come see the best of the world!
          </p>
        </div>

        <div className="mainContent grid">
         {
          data.map(({id,img,title,location,price,type})=>
          {
            return(
              <div data-aos = 'fade-up' className="singleOffer">
              <div className="destImage">
                <img src={img} alt={title} />
                <span className='discount'>
                  30% off
                </span>
  
              </div>
  
              <div className="offerBody">
                <div className="price flex">
                  <h4>
                    {price}
                  </h4>
                  <span className="status">
                    {type}
                  </span>
                </div>
                <div className="amenities flex">
                  <div className="singleAmenitiy flex">
                  <IoIosBed className='icon' />
                  <small> 2 Beds</small>
                  </div>
                  <div className="singleAmenitiy flex">
                  <MdBathtub className='icon' />
                  <small> 1 BathRoom</small>
                  </div> <div className="singleAmenitiy flex">
                  <FaWifi className='icon' />
                  <small> free Wifi</small>
                  </div> <div className="singleAmenitiy flex">
                  <MdAirportShuttle className='icon' />
                  <small> Shuttle</small>
                  </div>
                </div>
                <div className="location flex">
                <MdLocationPin className="icon"/>
                <small> {location}</small>
                </div>
                <button className="btn flex">
                  View Details
                  <BsArrowRightShort className='icon' />
                </button>
              </div>
            </div>
            )
          })
         }
        </div>
      </div>
    </section>
  )
}

export default Offers