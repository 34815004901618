import React, { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { FaUser } from 'react-icons/fa'
import { TbPassword } from 'react-icons/tb'
import './loginSignUp.css'
import Footer from '../Footer/Footer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Navbar from '../NavBar/Navbar'

function LoginSignUp() {
 
    const [action , setAction ] = useState('Login')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('');

    const navigate = useNavigate()

    const isLoggedIn = () => {
      const token = localStorage.getItem('token');
      return !!token; // return true if token exists
  };

  // Redirect to dashboard or any page if the user is logged in
  useEffect(() => {
      if (isLoggedIn()) {
          navigate('/dashboard'); // Adjust this route as needed
      }
  }, [navigate]);

   
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://uhospitality-backend.onrender.com/api/auth/login', {
        email,
        password,
      });
      setMessage(response.data.message || 'Login successful!');
      navigate(`/${response.data.user.role}`)
      // Optionally, store JWT token (if sent by the server) in localStorage for later use
      localStorage.setItem('token', response.data.token);
    } catch (error) {
      setMessage(error.response.data.message || 'Login failed!');
    }
  };

  // Handle Sign Up
  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://uhospitality-backend.onrender.com/api/auth/register', {
        username,
        email,
        password,
      });
      setMessage(response.data.message || 'Sign Up successful! Please check your email to verify.');
    } catch (error) {
      setMessage(error.response.data.message || 'Sign Up failed!');
    }
  };
  const disableEnter = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();
    }
};

  return (
    <>
    <Navbar />
    <section className='containerlogin'>
        <div className="header">
            <div className="text">{action}</div>
            <div className="underline"></div>
        </div>
        <form onSubmit={action === 'Login' ? handleLogin : handleSignUp} onKeyDown={disableEnter}>
        <div className="inputs">
           {
            action === 'Sign Up'? 
            (<div className="input">
            <FaUser className='icon'/>
            <input
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            type="text" placeholder='UserName'/>
        </div>): ''
           }
            <div className="input">
                <AiOutlineMail className='icon'/>
                <input 
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 required
                  type="email" placeholder='Email'/>
            </div>
            <div className="input">
                <TbPassword className='icon'/>
                <input 
                
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                type="password" placeholder='Password'/>
            </div>
        </div>
        {action === 'Sign Up'? <></> : <div className="forget-password">Lost Password? <span>Click here</span></div> }
        {message && <div className="message">{message}</div>}
        <div className="submit-container">
            <div className={action === 'Login'? 'submit gray' : 'submit'} onClick={()=> action === 'Login'? setAction('Sign Up'): handleSignUp}><button className='btn'> Sign Up</button> </div>
            <div className={action === 'Sign Up'? 'submit gray ': 'submit'} onClick={()=> action === 'Sign Up'? setAction('Login'): handleSignUp}> <button className='btn'>Login</button> </div>
        </div>
        </form>
    </section>
   
     <Footer />
     </>
  )
}

export default LoginSignUp