import React from 'react'
import './users.css'
import image from '../../../../assets/Conventionr.jpg'

const users = [
    {
        id: 1,
        name: 'John Doe',
        email: 'j0s7I@example.com',
    },
    {
        id: 2,
        name: 'John Doe',
        email: 'j0s7I@example.com',
    },
    {
        id: 3,
        name: 'John Doe',
        email: 'j0s7I@example.com',
    },
    
]
function Users() {
  return (
    <div className='user--list'>
        <div className='list--header'>
            <h1>Users</h1>
            <select name='Status'>
                <option value="">All</option>
                <option value="">Admin</option>
                <option value="">Client</option>
                <option value="">Blocked</option>
            </select>
        </div>
        <div className="list--container">
            {
                users.map((user, index) => (
                    <div className="list--item" key={index}>
                        <div className="user--detail">
                            <img src={image} alt="" />
                        </div>
                        <h3>{user.name}</h3>
                        <span>{user.email}</span>
                        <button className='btn'>View</button>
                        
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Users