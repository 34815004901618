import React from 'react'
import ProfileHeader from './ProfileHeader'
import './profile.css' 
import image from '../../../../assets/profile.jpg'

function Profile() {
  return (
    <div className='profile'>
      <ProfileHeader />

      <div className="user--profile">
        <div className="user--detail">
          <img src={image} alt="" />
          <h3 className='username'>Trophime KARASISI</h3>
          <span className='profession'>trophime3@gmail.com</span>
        </div>
        <div className="user--info"></div>
      </div>
    </div>
  )
}

export default Profile