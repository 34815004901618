import React from 'react'
import { BiNews, BiPlanet, BiUser } from 'react-icons/bi'
import { FaBookOpen, FaHandshake } from 'react-icons/fa'

const cards = [
    {
        title: 'Users',
        icon: <BiUser/>
    },
    {
        title: 'Destination',
        icon: <BiPlanet/>
    },
    {
        title: 'Blogs',
        icon: <BiNews/>
    },
    // {
    //     title: 'Offers',
    //     icon: <FaHandshake/>
    // },
    // {
    //     title: 'Bookings',
    //     icon: <FaBookOpen/>
    // },

]

function Card() {
  return (
    <div className='card--container'>
        {
            cards.map((card, index) => (
                <div className='card' key={index}>
                   <div className='card--cover'> {card.icon}</div> 
                    <div className="card--title">
                    <h2>{card.title}</h2>
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default Card