import React from 'react'
import ContentHeader from './ContentHeader'
import './content.css'
import Card from './Card'
import Users from '../Users/Users'

function Content() {
  return (
    <div className='content'>
      <ContentHeader />
      <Card />
      <Users />
    </div>
  )
}

export default Content