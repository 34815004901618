import React from 'react'
import './App.css'
import Navbar from './components/NavBar/Navbar'
import Home from './components/Home/Home'
import Popular from './components/Popular/Popular'
import Offers from './components/Offers/Offers'
import About from './components/About/About'
import Blog from './components/Blog/Blog'
import Footer from './components/Footer/Footer'
import LoginSignUp from './components/LoginSignUp/LoginSignUp'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import WebPages from './components/webPages'
import VerifyEmail from './components/LoginSignUp/VerifyEmail'
import SideBar from './components/Dashboard/components/NavBar/SideBar'
import Layout from './components/Dashboard/components/Layout/Layout'
const App = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Router>
        <Routes>
          <Route path='*' element={<WebPages />}/>
          <Route path='/login' element={<LoginSignUp />}/>
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/client/*" element={<Layout />} />
          <Route path="/admin/*" element={<Layout />} />
        </Routes>
      </Router>
     
    </div>
  )
}

export default App