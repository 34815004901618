import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // For extracting query params
import axios from 'axios';
import './verifyEmail.css';  // Optional, to style your component
import Footer from '../Footer/Footer';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  
  const navigate = useNavigate()

  // Extract token from query params
  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  };

  useEffect(() => {
    const token = getTokenFromUrl();
    if (!token) {
      setMessage('No token provided');
      setLoading(false);
      return;
    }

    // Make the request to the backend to verify the email
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`https://uhospitality-backend.onrender.com/api/auth/verify-email?token=${token}`);
        setMessage(response.data.message || 'Email verified successfully!');
        setTimeout(() => {
            navigate('/login');  // Use navigate to redirect to login page
          }, 3000);
      } catch (error) {
        setMessage(error.response?.data?.message || 'Email verification failed.');
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [location]);

  return (
    <>
    <div className="verify-email-container">
      {loading ? (
        <p>Verifying your email, please wait...</p>
      ) : (
        <p>{message}</p>
      )}
    </div>
      <Footer />
      </>
  );

};

export default VerifyEmail;
