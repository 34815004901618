import './popular.css'
import img from '../../assets/image1.jpg'
import img2 from '../../assets/image2.jpg'
import img3 from '../../assets/image4.jpg'
import img4 from '../../assets/lion.jpg'
import React, { useEffect, useState } from 'react';
import { BsArrowLeftShort, BsArrowRightShort, BsDot } from "react-icons/bs";
import './popular.css';
import axios from 'axios';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Data = [
  {
    id:1,
    image: img,
    destTitle: 'Nyungwe Canopy',
    location: 'Rwanda-West',
    grade: 'Higher walking'
    
  },
  {
    id:2,
    image: img2,
    destTitle: 'Kivu-Lake',
    location: 'Rwanda-West',
    grade: 'Diving and Fishing'
  },
  {
    id:3,
    image: img3,
    destTitle: 'Birunga',
    location: 'Rwanda-North',
    grade: 'Hiking'
  },
  {
    id:4,
    image: img4,
    destTitle: 'Akagera',
    location: 'Rwanda-East',
    grade: 'Tour'},]

const Popular = () => {
 
  // const [destinations, setDestinations] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  //   fetchDestinations(currentPage);
  // }, [currentPage]);

  // // Fetch destinations with axios
  // const fetchDestinations = async (page) => {
  //   try {
  //     const response = await axios.get(`/api/destinations?page=${page}`);
  //     const { destinations, totalPages } = response.data;
  //     setDestinations(destinations);
  //     setTotalPages(totalPages);
  //   } catch (error) {
  //     console.error('Error fetching destinations:', error);
  //   }
  // };

  // // Handle pagination
  // const handleNextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePrevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  return (
    <section id='popular' className='popular section container '>
      <div className="secContainer">
        <div className="secHeader flex">
          <div data-aos='fade-right' data-aos-duration='2500' className="textDiv">
            <h2 className="secTitle">Popular Destinations</h2>
            <p className="secText">
              From historical cities to natural spectacles, come see the best of the world!
            </p>
          </div>
          <div data-aos='fade-left' data-aos-duration='2500' className="iconsDiv flex">
            <BsArrowLeftShort className='icon leftIcon'  />
            <p>Page 1 of 4 </p>
            <BsArrowRightShort className='icon rightIcon'  />
          </div>
        </div>
        <div className="mainContent grid">
          {
            Data.map(({ _id, image, destTitle, description, location }) => {
              return (
                <div key={_id} data-aos='fade-up' className="singleDestination">
                  <div className="destinationImage">
                    <img src={image} alt={destTitle} />
                    <div className="overlayInfo">
                      <h3>{destTitle}</h3>
                      <p>{description}</p>
                      <BsArrowRightShort className='icon' />
                    </div>
                  </div>
                  <div className="destFooter">
                    <div className="number">
                      {/* 0{_id.slice(-4)} Short ID for display */}
                    </div>
                    <div className="destText flex">
                      <h6>{location}</h6>
                      <span className='flex'>
                        <span className='dot'>
                          <BsDot className='icon' />
                          Dot
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
          
      </div>
    </section>
  );
}

export default Popular;
