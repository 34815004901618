import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import './navbar.css'
import { IoIosPlanet } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";

import img1 from '../../assets/Flag-France.jpg'
import img2 from '../../assets/Flag-Rwanda.jpg'
import img3 from '../../assets/Flag-United-States-of-America.jpg'
import img4 from '../../assets/uganda.png'

const Navbar = () => {
  const [active, setActive] = useState('navBar')
  const [dropdown, setDropdown] = useState(false)
  const [transparent, setTransparent] = useState('header')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userRole, setUserRole] = useState(null) // New state to store user role

  const navigate = useNavigate(); // react-router-dom hook to navigate programmatically

  useEffect(() => {
    // Check for token and role
    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
    const role = localStorage.getItem('role'); // Assuming role is stored in localStorage

    if (token) {
      setIsLoggedIn(true);
      setUserRole(role); // Set user role state
    }
  }, []);

  const showNavbar = () => {
    setActive('navBar activeNavbar')
  }

  const removeNavbar = () => {
    setActive('navBar')
  }

  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent('header activeHeader')
    } else {
      setTransparent('header')
    }
  }

  window.addEventListener('scroll', addBg)

  const toggleDropdown = () => {
    setDropdown(!dropdown)
  }

  const handleDashboardRedirect = () => {
    // Navigate based on user role
    if (userRole === 'client') {
      navigate('/client'); // Navigate to client dashboard
    } else if (userRole === 'admin') {
      navigate('/admin'); // Navigate to admin dashboard
    } else {
      navigate('/client'); // Default dashboard if no role matches
    }
  }

  return (
    <section className='navBarSection'>
      <div className={transparent}>
        <div className="logoDiv">
          <a href='/' className="logo">
            <h1 className='flex'><IoIosPlanet className='icon' />
              UHDSS-Hospitality
            </h1>
          </a>
        </div>
        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem">
              <a href="/" className="navLink">Home</a>
            </li>
            <li className="navItem" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <p className="navLink">Destination</p>
              {/* Dropdown */}
              {dropdown && (
                <div className="dropdown">
                  <div className="dropdownItem">
                    <img src={img3} alt="USA Flag" className="flag" />
                    <div className="countryInfo">
                      <h3>USA</h3>
                      <ul>
                        <li>New York</li>
                        <li>Los Angeles</li>
                        <li>Miami</li>
                      </ul>
                    </div>
                  </div>
                  <div className="dropdownItem">
                    <img src={img1} alt="France Flag" className="flag" />
                    <div className="countryInfo">
                      <h3>France</h3>
                      <ul>
                        <li>Paris</li>
                        <li>Lyon</li>
                        <li>Marseille</li>
                      </ul>
                    </div>
                  </div>
                  <div className="dropdownItem">
                    <img src={img2} alt="Rwanda Flag" className="flag" />
                    <div className="countryInfo">
                      <h3>Rwanda</h3>
                      <ul>
                        <li>Kigali</li>
                        <li>Musanze</li>
                        <li>Rubavu</li>
                      </ul>
                    </div>
                  </div>
                  <div className="dropdownItem">
                    <img src={img4} alt="Uganda Flag" className="flag" />
                    <div className="countryInfo">
                      <h3>Uganda</h3>
                      <ul>
                        <li>Kampala</li>
                        <li>Entebe</li>
                        <li>Kasese</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className="navItem">
              <a href="#blog" className="navLink">Blog</a>
            </li>
            <li className="navItem">
              <a href="#contact" className="navLink">Contact</a>
            </li>
            <li className="navItem">
              <a href="https://www.hotels-apartment.com/" target='_blank' className="navLink">Hotels</a>
            </li>
            <li className="navItem">
              <a href="#about" className="navLink">About</a>
            </li>
            <div className="headerBtn flex">
              <button className='btn loginButton' onClick={handleDashboardRedirect}>
                {isLoggedIn ? <h4 className='dash'>Dashboard</h4> : <a href="/login">Login</a>}
              </button>
              {/* <button className='btn'>
                <a href="#" >Sign Up</a>
              </button> */}
            </div>
          </ul>
          <div onClick={removeNavbar} className="closeNavbar">
            <AiFillCloseCircle className='icon' />
          </div>
        </div>
        <div onClick={showNavbar} className="toggleNavbar">
          <TbGridDots className='icon' />
        </div>
      </div>
    </section>
  )
}

export default Navbar
