import React, { useEffect, useState } from 'react'
import './blog.css'
import { BsArrowRightShort } from "react-icons/bs";
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const Blog = () => {

  const [blogs, setBlogs] = useState([]); // Initialize as an array
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchAllBlog = async () => {
      try {
        const response = await axios.get(`https://uhospitality-backend.onrender.com/api/blogs?page=${currentPage}&limit=6`);
        setBlogs(response.data.blogs);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllBlog();
  }, [currentPage]);

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section id='blog' className='blog section container'>
      <div className="secContainer grid">
        <div className="secIntro">
          <h2 data-aos='fade-up' data-aos-duration='2500' className='secTitle'>Our Best Blogs</h2>
          <p data-aos='fade-up' data-aos-duration='2500'>
            An insight to the incredible experience in the world.
          </p>
        </div>

        <div className="mainContainer grid">
          {blogs.length > 0 ? (
            blogs.map((blog) => {
              return (
                <div key={blog._id} data-aos='fade-up' className="singlePost grid">
                  <div className="imgDiv">
                    <img src={blog.photoUrl} alt={blog.title} />
                  </div>
                  <div className="postDetail">
                    <h3>{blog.title}</h3>
                    <p>{blog.content}</p>
                  </div>
                  <a href={blog.link} className='flex'>
                    Read More
                    <BsArrowRightShort className='icon' />
                  </a>
                </div>
              );
            })
          ) : (
            <p>No blogs available at the moment.</p>
          )}
        </div>

        {/* <div className="pagination">
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          <span>{currentPage} of {totalPages}</span>
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
        </div> */}
      </div>
    </section>
  )
}

export default Blog;
